html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.version {
    position: absolute;
    font-size: 15px;
    color: gray;
    bottom: 26px;
    text-align: center;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.github {
    cursor: pointer;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url("./assets/github.png");
    background-size: cover;
    display: block;
    width: 46px;
    height: 46px;
    margin: 4px;
    border-radius: 28px;
    display: inline-block;
    right: 55px;
    top: 25px;
    z-index: 1999;
    transform: translateY(-20px);
}

.github::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: inset 0 0 10px #fff06a, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, inset 4px 0 16px #f0f, inset -4px 0 16px #0ff, 0 0 10px #fff06a, -6px 0 36px #f0f, 6px 0 36px #0ff;
    -webkit-animation: rotate 3s linear infinite;
    animation: rotate 3s linear infinite;
}

#root {
    height: 100%;
    width: 100%;
    display: flex;
    background: #fafafa;
}

.margin-right-19 {
    margin-right: 19px;
}

.btn-container {
    width: 52px;
    height: 52px;
    border-radius: 30px;
    background-color: #000;
    opacity: 0.4;
}

.room {
    display: flex;
    flex: 1;
    height: 100vh;
    /*min-width: 1210px;*/
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
}

.layout-player-main {
    /*display: flex;*/
    flex: 1;
    /*position: relative;*/
    /*margin-right: 410px;*/
    /*min-width: 800px;*/
}

/*.live {*/
/*    display: flex;*/
/*    !*flex: 1;*!*/
/*    align-items: center;*/
/*    position: relative;*/
/*    !*min-width: 1210px;*!*/
/*    margin-right: auto;*/
/*    margin-left: auto;*/
/*}*/

.live {
    display: flex;
    flex: 1;
    height: 100vh;
    /*min-width: 1210px;*/
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
}

/*.layout-main-live {*/
/*    !*display: flex;*!*/
/*    !*position: relative;*!*/
/*    padding-top: 10px;*/
/*    padding-right: 120px;*/
/*    padding-left: 120px;*/
/*}*/

.layout-header {
    display: flex;
    height: 50px;
    flex: 0 0 auto;
    box-sizing: border-box;
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbe2e5;
}

.layout-player {
    display: flex;
    flex: 1;
    /*height: calc(100vh - 50px);*/
}

.layout-header-live {
    display: flex;
    height: 50px;
    flex: 0 0 auto;
    box-sizing: border-box;
    background-color: #f4f6f7;
}

.layout-player-live {
    display: flex;
    flex: 1;
}
/*.layout-player-main-live {*/
/*    !*display: flex;*!*/
/*    !*flex: 1;*!*/
/*    position: relative;*/
/*    margin-right: 410px;*/
/*    !*min-width: 800px;*!*/
/*}*/

.layout-player-main-live {
    /*display: flex;*/
    /*position: relative;*/
    flex: 1;
}

.playback {
    display: flex;
    flex: 1;
    /*width: 100vw;*/
    height: 100vh;
    flex-wrap: nowrap;
    /*min-width: 1210px;*/
    flex-direction: column;
    position: relative;
    background-color: #ffffff;
}

.layout-header-playback {
    display: flex;
    height: 50px;
    flex: 0 0 auto;
    box-sizing: border-box;
    background-color: #f4f6f7;
}

/*.layout-footer-playback {*/
/*    flex: 0 0 50px;*/
/*    box-sizing: border-box;*/
/*    background-color: #f4f6f7;*/
/*}*/

.layout-player-playback {
    display: flex;
    flex: 1;
    /*height: calc(100% - 100px);*/
    /*overflow-y: auto;*/
}

.layout-player-main-playback {
    /*display: flex;*/
    /*position: relative;*/
    flex: 1;
    /*width: calc(100% - 400px);*/
    /*min-width: 960px;*/
    /*flex-direction: column;*/
}

.current-view {
    display: flex;
    flex: 1;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #666;
    background-size: 280px;
    background-image: url('./assets/logo-open-live-gray.png');
    height: calc(100vh - 50px);
}

.stream-player {
    display: flex;
    flex: 1;
}

.stream-container {
    left: 30px;
    top: 91px;
    height: 660px;
    width: 233px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
}

.stream-container > .stream-player {
    max-height: 150px;
    background: rgba(216, 216, 216, 1);
    max-width: 200px;
    margin: 4px 0;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}

.avatar-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 44px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 24px;
    flex-direction: row;
    box-sizing: border-box;
    width: 200px;
    margin-left: 30px;
    margin-top: 30px;
}

.default-avatar {
    /*background-color: #fff de;*/
    /*background-image: url("./assets/avatar.png");*/
    background-size: 38px;
    display: block;
    width: 38px;
    height: 38px;
    margin: 3px;
    margin-left: 0;
    border-radius: 28px;
}

.default-avatar img {
    height: 38px;
    width: 38px;
}

.autoplay {
    cursor: pointer;
}

.avatar-text {
    display: flex;
    justify-content: center;
    border-radius: 28px;
    width: 100px;
    height: 20px;
    align-items: center;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'HelveticaNeue';
    color: rgba(255, 255, 255, 1);
    line-height: 14px;
}

.nav {
    position: absolute;
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-between;
    z-index: 3;
}

.quit {
    display: block;
    margin-top: 36px;
    margin-right: 36px;
    background-image: url("./assets/icon-exit-hover.png");
    background-size: 32px;
    width: 32px;
    height: 32px;
    border-radius: 30px;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
}

.quit:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.like {
    cursor: pointer;
    background-image: url("./assets/icon-add-blue.png");
    background-size: 30px;
    display: block;
    width: 30px;
    height: 30px;
    margin: 4px;
    border-radius: 28px;
}

.like:hover {
    background-image: url("./assets/icon-add-blue.png");
}

.stream-uid {
    padding: 2px 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    left: 5px;
    bottom: 7px;
    z-index: 2;
    position: absolute;
    border-radius: 28px;
    max-width: 100px;
    max-height: 20px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    font-size: 12px;
    font-family: HelveticaNeue;
    color: rgba(255, 255, 255, 1);
    line-height: 14px;
}

.mute-video {
    background-image: url("./assets/icon-camera.png");
}

.mute-video:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.unmute-video {
    background-image: url("./assets/icon-camera-off.png");
}

.unmute-video:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.mute-audio {
    background-image: url("./assets/icon-microphone.png");
}

.mute-audio:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.unmute-audio {
    background-image: url("./assets/icon-microphone-off.png");
}

.unmute-audio:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.stop-screen-share {
    background-image: url("./assets/icon-share.png");
}

.stop-screen-share:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.start-screen-share {
    background-image: url("./assets/icon-share.png");
}

.start-screen-share:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.replace-screen-share {
    background-image: url("./assets/icon-replace.png");
}

.replace-screen-share:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.change-screen-share {
    background-image: url("./assets/icon-change.png");
}

.change-screen-share:hover {
    background-color: #44a2fc;
    opacity: 1;
}

.show-profile {
    background-image: url("./assets/icon-text.png");
}

.show-profile:hover {
    opacity: 1;
    background-image: url("./assets/icon-text-actived.png");
    background-color: #44a2fc;
}

.back-btn {
    background-size: 32px;
    background-image: url("./assets/icon-back.png");
    background-repeat: no-repeat;
    top: 1rem;
    height: 32px;
    position: absolute;
    width: 32px;
    left: 1rem;
    cursor: pointer;
    z-index: 2;
}

.back-btn:hover {
    background-image: url("./assets/icon-back-hover.png");
}

.setting-btn {
    background-size: 32px;
    background-image: url("./assets/icon-setting.png");
    background-repeat: no-repeat;
    top: 1rem;
    height: 32px;
    position: absolute;
    width: 32px;
    right: 1rem;
    cursor: pointer;
    z-index: 2;
}

.setting-btn:hover {
    background-image: url("./assets/icon-setting-hover.png");
}

.role-container {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-around;
}

.role-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.inactive:hover .role-name {
    color: #44a2fc;
}

.inactive:hover .icon-audience {
    background-image: url("./assets/icon-audience.png");
}

.inactive:hover .icon-host {
    background-image: url("./assets/icon-host.png");
}

.inactive .role-name {
    padding-left: 5px;
    font-size: 14px;
    color: #eaeaea;
}

.active .role-name {
    padding-left: 5px;
    font-size: 14px;
    color: #44a2fc;
}

.custom-radio {
    position: relative;
    width: 14px;
    height: 14px;

    /* When the radio button is checked, add a blue background */
}

.custom-radio:hover [type="radio"] ~ .checkmark {
    background-color: #ccc;
}

.custom-radio [type="radio"]:checked ~ .checkmark {
    background-color: #2196f3;
}

.custom-radio [type="radio"],
.custom-radio .checkmark {
    width: 14px;
    height: 14px;
}

.custom-radio [type="radio"] {
    margin: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    z-index: 4;
}

.custom-radio [type="radio"]:checked ~ .checkmark::after {
    display: block;
}

.custom-radio .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
}

.custom-radio .checkmark {
    top: 0;
    left: 0;
    background-color: #eee;
    border-radius: 50%;
}

.radio-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 75px;
    max-width: 75px;
    position: absolute;
    bottom: top;
    top: 132px;
    left: 19px;
}

.radio-row.host {
    left: 22px;
}

.radio-row.audience {
    left: 13px;
}

.error-icon {
    padding-right: 10px;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-image: url("./assets/icon-error.png");
}

.cover-image {
    margin-top: 4rem;
    width: 180px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("./assets/logo-open-live.png");
}

.active .icon-audience {
    width: 100px;
    height: 118px;
    background-size: 100px 118px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/icon-audience.png");
}

.inactive .icon-audience {
    width: 100px;
    height: 118px;
    background-size: 100px 118px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/icon-audience-inactived.png");
}

.active .icon-host {
    width: 100px;
    height: 118px;
    background-size: 100px 118px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/icon-host.png");
}

.inactive .icon-host {
    width: 100px;
    height: 118px;
    background-size: 100px 118px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./assets/icon-host-inactived.png");
}

.stream-container .stream-profile {
    top: 12px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    padding: 5px 5px;
}

.main-stream-profile {
    width: 150px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 3;
    text-shadow: black 0.1em 0.1em 0.2em;
    font-size: 14px;
    color: #fff;
    right: 80px;
    top: 30px;
}

.stream-profile {
    left: 0;
    z-index: 2;
    color: #fff;
    opacity: 0.9;
    text-shadow: black 0.1em 0.1em 0.2em;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    position: absolute;
}

.layout-player-aside {
    display: flex;
    flex: 0 0 auto;
    width: 400px;
    height: calc(100vh - 50px);
    flex-direction: column;
    box-sizing: border-box;
    border-left: 1px solid #dbe2e5;
}

/*.layout-player-asideMain {*/
/*    display: flex;*/
/*    */
/*}*/

/*.layout-player-aside-live {*/
/*    display: flex;*/
/*    position: absolute;*/
/*    width: 402px;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*}*/

/*.layout-player-asideMain-live {*/
/*    display: flex;*/
/*    flex: 1;*/
/*    overflow: hidden;*/
/*    position: absolute;*/
/*    flex-direction: column;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    border: 1px solid #e4e3e3;*/
/*    background-color: #ffffff;*/
/*    border-radius: 4px;*/
/*}*/

.layout-player-aside-live {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 400px;
    height: calc(100vh - 50px);
    box-sizing: border-box;
    border-left: 1px solid #b2b8ba;
}

/*.layout-player-asideMain-live {*/
/*    display: flex;*/
/*    flex: 1;*/
/*    flex-direction: column;*/
/*    border-left: 1px solid #dbe2e5;*/
/*}*/

/*.camera {*/
/*    flex: 0 0 300px;*/
/*    order: -1;*/
/*}*/
.layout-player-chat-info {
    display: flex;
    flex: 1;
    flex-direction: column;
    /*box-sizing: border-box;*/
    height: 100%;
}
.layout-player-chat-info ul {

}

.tab-container {
    /*background-color: #ffffff;*/
    flex: 1;
    /*height: 100%;*/
}

.tab-container ul {
    padding-left: 0;
    margin: 0;
}

.tab-container ul li {
    list-style-type: none;
}

.tab-panel {
    height: 100%;
    /*margin: 4px;*/
    width: 100%;
}

.tab-panel-content {
    display: flex;
    height: calc(100vh - 160px);
    flex-direction: column;
}

.chatTabContainer {
    display: flex;
    flex: 1;
    /*position: relative;*/
    box-sizing: border-box;
    height: 100%;
}

.chatTabContainer-live {
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 0;*/
    /*bottom: 51px;*/
    /*left: 0;*/
    flex: 1;
}

.chatTabContainer ul {
    padding-left:0px;
    margin: 0px;

}

.chatTabContainer ul li {
    list-style-type: none;
    margin: 1px 0px;

}

.chatTabContainer ul li div {
    display: table;
}

.chatTabContainer ul li :nth-child(2) {
    /*color: #2b94ff;*/
    margin-right: 1em;
}

.chatTabContainer ul li span {
    font-size:17px;
    font-family: -apple-system, "Microsoft Yahei", sans-serif;
    text-align: center;
}

.chatTabContainer ul li span img {
    height: 1em;
    width: 1em;
}

.chat-bar {
    flex: 0 0 62px;
}

.chat-send-text {
    box-sizing: content-box;
    resize: none;
    float: left;
    width: 329px;
    height: 50px;
    padding: 5px 10px 5px 10px;
    line-height: 2;
    font-size: 12px;
    background: #fff;
    border: 1px solid #ff921a;
    border-right: 0;
    border-radius: 0 0 0 4px;
    outline: none;
    overflow: hidden;
}

.chat-send-button {
    display: block;
    width: 49px;
    height: 62px;
    line-height: 62px;
    border-radius: 0 0 4px 0;
    background: #f70;
    color: #fff;
    font-size: 14px;
    float: left;
    text-align: center;
    cursor: pointer;
}

.chat-send-button:hover {
    background: #f14700;
}

.chat-bar-live {
    flex: 0 0 62px;
    /*margin-top: auto;*/
}

.message-content {
    flex: 1;
    /*height: calc(100vh - 160px);*/
    padding: 16px;
    /*overflow: auto;*/
    /*height: 523px;*/
    /*height: 823px;*/
    word-break:break-all;
}

.message-content ul li div {
    display: table;
}

.message-content ul li :nth-child(2) {
    margin-right: 1em;
}

.message-content ul li span {
    font-size: 12px;
    /*font-family: -apple-system, Microsoft Yahei, sans-serif;*/
    font-family: SimSun;
    text-align: center;
}

.message-content ul li span img {
    height: 1em;
    width: 1em;
}

.user-count {
    height: 20px;
    text-align: center;
    padding-top: 8px;
}

.user-count span {
    color: #f14700;
}

.user-list {
    /*height: 503px;*/
    /*height: 100%;*/
}

.layout-player-title {
    position: relative;
    background-color: #ffffff;
    min-height: 100px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #e4e3e3;
}

.live-title {
    position: relative;
    padding: 8px 10px 8px 108px;
    height: 100px;
}

.layout-player-video {
    display: flex;
    flex: 1;
    min-width: 400px;
    background-color: #545454;
    height: calc(100vh - 50px);
    /*overflow: hidden;*/
    /*position: relative;*/
    /*height: 0;*/
    /*!*top: 0;*!*/
    /*!*right: 0;*!*/
    /*!*bottom: 0;*!*/
    /*!*left: 0;*!*/
    /*padding-top: 56.25%;*/
}

.live-player {
    flex: 1;
    max-width: 996px;
    margin: auto;
    /*height: calc(100vh - 50px);*/
}

.layout-player-info {
    display: flex;
    height: calc(100vh - 50px);
    flex: 1;
}

.layout-player-video-playback {
    display: flex;
    /*width: 100%;*/
    /*overflow-x: auto;*/
    flex: 1;
    /*align-items: center;*/
    /*align-content: center;*/
    min-width: 400px;
    /*justify-content: center;*/
    /*align-items: center;*/
    background-color: #545454;
}

.video-playback {
    flex: 1;
    /*flex: 1 1 auto;*/
    /*overflow-y: auto;*/
    /*height: 0;*/
    max-width: 996px;
    margin: auto;
}

.layout-player-video-list {
    /*min-width: 104px;*/
    display: flex;
    flex-direction: column;
    width: 237px;
    /*width: 104px;*/
    flex: 0 0 auto;
    /*overflow-y: auto;*/
    /*height: calc(100% - 100px);*/
    /*overflow: hidden;*/
    /*height: 0;*/
    box-sizing: border-box;
    border-left: 1px solid #b2b8ba;
}

.video-list-title {
    flex: 0 0 46px;
    padding: 4px;
    text-align: center;
    box-sizing: border-box;
    /*border-bottom: 1px solid #dbe2e5;*/
    background-color: #ffffff;
}

.video-list-title > div {
    line-height: 33px;
    color: #326E91;
    border: 1px solid #dbe2e5;
    border-radius: 8px 8px 0 0;
}

.number-box {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
    /*width: 0;*/
    /*height: 0;*/
    overflow: auto;
    /*height: calc(100% - 50px);*/
    height: 100%;
    /*overflow-y: auto;*/
    margin: 0 4px 4px 4px;
    border: 1px solid #dbe2e5;
    border-radius: 0 0 8px 8px;
}

.video-button-item {
    margin: 10px 20px;
}

.layout-player-aside-playback {
    display: flex;
    width: 400px;
    flex: 0 0 auto;
    flex-direction: column;
    /*max-width: 400px;*/
    /*height: 100%;*/
    /*height: calc(100vh - 50px);*/
    /*overflow: auto;*/
    box-sizing: border-box;
    border-left: 1px solid #b2b8ba;
}

.layout-player-chat-record {
    display: flex;
    flex-direction: column;
    flex: 1;
    /*height: 100%;*/
}

.chat-record-title {
    flex: 0 0 46px;
    height: 100%;
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    box-sizing: border-box;
    /*border-bottom: 1px solid #dbe2e5;*/
    background-color: #ffffff;
}

.chat-record-title > div {
    line-height: 33px;
    color: #326E91;
    border: 1px solid #dbe2e5;
    border-radius: 8px 8px 0 0;
}

.chat-record-content {
    height: 100%;
    margin: 0 4px 4px 4px;
    border: 1px solid #dbe2e5;
    border-radius: 0 0 8px 8px;
}

.chat-record-box {
    height: calc(100vh - 134px);
    padding: 16px;
    overflow: auto;
    /*margin: 4px;*/
    /*border: 1px solid #dbe2e5;*/
    /*border-radius: 4px;*/
    word-break:break-all;
}

/*.chat-record-box > div {*/
/*    border:  1px solid #dbe2e5;*/
/*}*/

.chat-record-box ul {
    padding-left: 0;
    margin: 0;
}

.chat-record-box ul li {
    list-style-type: none;
}

.chat-record-box ul li span {
    color: #009100;
}

.chat-record-box ul li :nth-child(3) {
    margin-right: 1em;
}

.chat-record-item {
    padding: 5px;
}
